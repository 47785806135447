<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="parent_id">Account:</label>
          <Select2 name="account_id" v-model="account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="vehicle_id" :options="vehicleOptions" @change="onChangeVehicle($event)" @select="onChangeVehicle($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="type">GPS Command:</label>
        <select class="form-control" name="type" required v-model="type">
          <option value="">--- Please Select One ---</option>
          <option v-for="v in vehicle_commands" :key="v" :value="v.type">{{v.title}}</option>
        </select>
      </div>
      <div v-if="this.type == 'custom'" class="form-group col-sm-12 col-md-12 required">
        <label for="data">Custom GPS Command Data:</label>
        <textarea class="form-control" name="data" v-model="data" required></textarea>
        <small>RAW command (only text commands are supported)<br/>
You can use these variables:
[%IMEI%] - the selected device imei</small>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-warning" type="submit" value="Send Command" :disabled="this.onRequest">
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('vehicle_commands', ['vehicle_commands', 'onRequest']),
    },
    data(){
      return {
        account_id: '',
        vehicle_id: '',
        type: '',
        data: '',
        vehicleOptions: [],
        accountOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {vehicle_id, type, data} = this
        this.send_vehicle_command({vehicle_id, type, data})
      },
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicle_commands', ['send_vehicle_command', 'get_vehicle_command', 'reset_vehicle_command']),
      ...mapActions('alert', ['clear']),
      onChange(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.vehicle_id = ''
        this.type = ''
        this.data = ''
        this.reset_vehicle_command()
      },
      onChangeVehicle(event){
        this.get_vehicle_command({vehicle_id: parseInt(event.id)})
        this.type = ''
        this.data = ''
        this.reset_vehicle_command()
      },
    },
    created() {
      this.$emit('onChildInit', 'Send GPS Command')
      this.reset_vehicle_command()
      this.get_all()
    },
    components: {
      Select2
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
