<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20pt" height="20pt" viewBox="0 0 50 50" version="1.1">
<g id="surface1">
<path :style="'stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 25 0 C 13.367188 0 3.949219 1.316406 3.949219 10.527344 L 3.949219 36.84375 C 3.949219 39.171875 4.972656 41.238281 6.578125 42.683594 L 6.578125 47.367188 C 6.578125 48.816406 7.761719 50 9.210938 50 L 11.84375 50 C 13.300781 50 14.472656 48.816406 14.472656 47.367188 L 14.472656 44.738281 L 35.527344 44.738281 L 35.527344 47.367188 C 35.527344 48.816406 36.699219 50 38.15625 50 L 40.789062 50 C 42.238281 50 43.421875 48.816406 43.421875 47.367188 L 43.421875 42.683594 C 45.027344 41.238281 46.050781 39.171875 46.050781 36.84375 L 46.050781 10.527344 C 46.050781 1.316406 36.632812 0 25 0 Z M 13.15625 39.472656 C 10.972656 39.472656 9.210938 37.710938 9.210938 35.527344 C 9.210938 33.34375 10.972656 31.578125 13.15625 31.578125 C 15.34375 31.578125 17.105469 33.34375 17.105469 35.527344 C 17.105469 37.710938 15.34375 39.472656 13.15625 39.472656 Z M 36.84375 39.472656 C 34.65625 39.472656 32.894531 37.710938 32.894531 35.527344 C 32.894531 33.34375 34.65625 31.578125 36.84375 31.578125 C 39.027344 31.578125 40.789062 33.34375 40.789062 35.527344 C 40.789062 37.710938 39.027344 39.472656 36.84375 39.472656 Z M 40.789062 23.683594 L 9.210938 23.683594 L 9.210938 10.527344 L 40.789062 10.527344 Z M 40.789062 23.683594"/>
</g>
</svg>
</template>
<script>
  export default {
    props: ['fill']
  }
</script>
