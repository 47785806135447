<template>
  <div class="row">
    <div class="col-lg-12 text-center">
      <h4>{{label}}</h4>
    </div>
    <div class="col-lg-12">
      <LineChart :chartData="chartData" :options="options"/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { LineChart } from 'vue-chart-3';

export default defineComponent({
  components: { LineChart },
  computed:{
    chartData(){
      if(this.data){
        let labels = []
        let datasets = []
        let dataset = {}
        let dataset_data = []
        this.data.map(item => {
          labels.push(item.label)
          dataset_data.push(item.data)
        })
        dataset.data = dataset_data
        dataset.label = this.label
        dataset.borderColor = 'rgb(0, 123, 255)'
        dataset.backgroundColor = 'rgb(0, 123, 255)'
        datasets.push(dataset)
        return {
          labels: labels,
          datasets: datasets,
        }
      }
      return {
        labels: [],
        datasets:[]
      }
    },
    options(){           
      let that = this 
      return {
        maintainAspectRatio: false,
        elements: {
            point:{
                radius: 0
            }
        },
        scales:{
          y: {
            ticks: {
              callback: function(value) {
                  return value + (that.uom ? that.uom : '');
              }
            }
          }
        },
        plugins:{
          legend: {
            display: false
          },
          tooltips: {
            enabled: true
          }
        }
      }
    }
  },
  props:['data', 'label', 'uom'],
});
</script>
