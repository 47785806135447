<template>
  <div v-if="entries && columns" :key="entries">
    <div class="table-responsive">
      <table class="table table-bordered table-striped" id="table_data">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column" :class="(column.raw && column.raw.type == 'Action' ? 'notexport' : '')">{{column.text}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="entries.length < 1" :key="entries.length">
            <td :colspan="columns.length" class="text-center">No data available in table</td>
          </tr>
          <tr v-for="(data, index) in entries" :key="index" @click="onRowClicked(data)" :class="(this.onRowClickEvent && this.selectedRow != null && this.selectedRow == data ? 'selected' : '')">
            <td v-for="column in columns" :key="column" :class="(this.onRowClickEvent ? 'hoverable' : '')+' '+ column.class">
              <span v-if="column.raw">
                <span v-if="column.raw.type == 'Text'">
                  <span v-for="field in column.raw.fields" :key="field">
                    <span v-if="field.value == data[column.name]" :class="field.class">{{field.text}}</span>
                  </span>
                </span>
                <span v-if="column.raw.type == 'Action'" style="display:flex;">
                  <span v-for="field in column.raw.fields" :key="field">
                    <router-link v-if="field.method == 'get' && checkEnable(field, data)" :class="field.class" :to="{ name: field.event, params: {id: data.id }}">{{field.text}}</router-link>
                    <router-link v-if="field.method == 'get_newtab' && checkEnable(field, data)" :class="field.class" :to="{ name: field.event, params: {id: data.id }}" target="_blank">{{field.text}}</router-link>
                    <a v-if="field.method == 'open_newtab'" target="_blank" :class="field.class" :href="data[field.url]">{{field.text}}</a>
                    <form v-if="field.method == 'post' && checkEnable(field, data)" @submit.prevent="handleSubmit(field.event, data.id)">
                      <button :class="field.class">{{field.text}}</button>
                    </form>
                    <button v-if="field.method == 'print-work-order' && checkEnable(field, data)" :class="field.class" @click.prevent="handlePrintWorkOrder(data)">{{field.text}}</button>
                    <button v-if="field.method == 'print-trip' && checkEnable(field, data)" :class="field.class" @click.prevent="handlePrintTrip(data)">{{field.text}}</button>
                    <button v-if="field.method == 'print-spda' && checkEnable(field, data)" :class="field.class" @click.prevent="handlePrintSpda(data)">{{field.text}}</button>
                    <button v-if="field.method == 'print-case-incident' && checkEnable(field, data)" :class="field.class" @click.prevent="handlePrintCaseIncident(data)">{{field.text}}</button>
                    <button v-if="field.method == 'raw_btn' && checkEnable(field, data)" :class="field.class" @click.prevent="this.$emit('onBtnClicked', data)">{{field.text}}</button>
                    <button v-if="field.method == 'print-delivery' && checkEnable(field, data)" :class="field.class" @click.prevent="handlePrintDelivery(data)">{{field.text}}</button>
                    <button v-if="field.method == 'print-cost' && checkEnable(field, data)" :class="field.class" @click.prevent="handlePrintCost(data)">{{field.text}}</button>
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub'">
                  <span v-if="data[column.name] != null">
                    {{data[column.name][column.raw.value]}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub_text'">
                  <span v-if="data[column.name] != null">
                    <span v-for="field in column.raw.fields" :key="field">
                      <span v-if="field.value == data[column.name][column.raw.value]" :class="field.class">{{field.text}}</span>
                    </span>
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub_sub'">
                  <span v-if="data[column.name] != null && data[column.name][column.raw.sub] != null">
                    {{data[column.name][column.raw.sub][column.raw.value]}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Color'" v-bind:style="{backgroundColor:data[column.name],padding:'5px',color:'white'}">
                  {{data[column.name]}}
                </span>
                <span v-if="column.raw.type == 'Moment'">
                  {{convertMoment(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'format_number'">
                  {{formatNumber(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'decimal_number'">
                  {{(data[column.name] !== undefined && data[column.name] !== null ? (data[column.name]).toFixed(column.raw.decimal > -1 ? column.raw.decimal : 0) : "")}}
                </span>
                <span v-if="column.raw.type == 'decimal_number_pct'">
                  {{(data[column.name] !== undefined && data[column.name] !== null ? (data[column.name]).toFixed(column.raw.decimal > -1 ? column.raw.decimal : 0) + "%" : "")}}
                </span>
                <span v-if="column.raw.type == 'Moment_UTC'">
                  {{convertUtc(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'Sub_Moment_UTC'">
                  <span v-if="data[column.name] != null">
                    {{convertUtc(data[column.name][column.raw.value])}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Sub_format_number'">
                  <span v-if="data[column.name] != null">
                    {{formatNumber(data[column.name][column.raw.value])}}
                  </span>
                </span>
                <span v-if="column.raw.type == 'Moment_Time'">
                  {{convertTime(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'Moment_date'">
                  {{convertDate(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'Moment_date_expiring'" :style="'color:'+getColor(data[column.name])">
                  {{convertDateExpiring(data[column.name])}}
                </span>
                <span v-if="column.raw.type == 'gps_lat_lng'">
                  <a target="_blank" :href="'https://maps.google.com/?q='+data.lat+','+data.lng">Google</a>
                </span>
              </span>
              <span v-else>{{data[column.name]}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else>
  </div>
</template>
<script>
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons-dt"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery';
import moment from 'moment'
export default {
  data(){
    return {
      dt: null,
      selectedRow: null
    }
  },
  watch:{
    entries(nextState){
      if(!nextState || nextState.length < 1) return;
      const self = this
      this.$nextTick(() => {
        let orderType = 'asc', orderIndex = 0
        self.columns.map((item, index) => {
          if(item.order){
            orderType = item.order
            orderIndex = index
          }
        })
        self.dt = $('#table_data').DataTable({
          dom: 'Bfrtip',
          buttons: ['pageLength', {
              extend: 'excel',
              title: this.title ? this.title : '',
              exportOptions: {
                  columns: ':not(.notexport)'
              }
          },{
              extend: 'pdf',
              title: this.title ? this.title : '',
              exportOptions: {
                  columns: ':not(.notexport)'
              },
              orientation:'landscape',
              customize: function (doc) {
                doc.content[1].table.widths = Array(doc.content[1].table.body[0].length + 1).join('*').split('');
              }
          },{
              extend: 'csv',
              title: this.title ? this.title : '',
              exportOptions: {
                  columns: ':not(.notexport)'
              },
          },{
              extend: 'print',
              title: this.title ? this.title : '',
              exportOptions: {
                  columns: ':not(.notexport)'
              },
          }],
          processing: true,
          bPaginate: true,
          "pageLength": self.currEntries ? self.currEntries : 10,
          order: [orderIndex, orderType]
        })
      });
    }
  },
  methods:{
    handleSubmit(event, id){
      if(confirm('Are you sure?')){
        this.$store.dispatch(event, id, { root: true })
      }
    },
    convertMoment(item){
      if(item)
        return moment(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
      else
        return ''
    },
    convertUtc(item){
      if(item)
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
      else
        return ''
    },
    convertDate(item){
      if(item)
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD")
      else
        return ''
    },
    convertTime(item){
      if(item)
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
      else
        return ''
    },
    convertDateExpiring(item){
      if(item){
        let day = moment(item).fromNow()
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD") + " ( "+day+" )"
      }
      else
        return ''
    },
    formatNumber(value){
      return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
    },
    onRowClicked(item){
      if(this.onRowClickEvent){
        this.selectedRow = item
        this.onRowClickEvent(item)
      }
    },
    getColor(item){
      let day = moment(item).diff(moment(), 'days')
      if(day <= 30){
        return '#dc3545'
      }
      return '#ffc107'
    },
    checkEnable(field, data){
      if(!field.check) {
        return true
      }
      if(data[field.check.name] == field.check.value){
        return true
      }
      return false
    },
    handlePrintWorkOrder(data){
      $('#section-to-print').empty()
      var text_html = `<table style="width:100%">
        <tr>
          <td style="text-align:left"><h3>`+data.account.account_name+`</h3></td>
          <td></td>
          <td style="text-align:right"><h3>Work Order</h3></td>
        </tr>
        <tr>
          <td colspan="2">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:left">Work Order #</th>
                <td>`+data.work_order_code+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle No</th>
                <td>`+data.vehicle.vehicle_no+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle Code</th>
                <td>`+data.vehicle.vehicle_code+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Start</th>
                <td>`+moment(data.start).format('lll')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Finish</th>
                <td>`+moment(data.end).format('lll')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Type</th>
                <td>`+(data.type == 1 ? 'Service' : data.type == 2 ? 'Inspection' : data.type == 3 ? 'Repair' : 'Others')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Service By</th>
                <td>`+(data.service_by == 1 ? '3rd Party / Vendor' : 'Own Workshop')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Priority</th>
                <td>`+(data.type == 1 ? 'Low' : data.type == 2 ? 'Medium' : 'High')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Maintenance Schedule Name</th>
                <td>`+(data.maintenance_schedule ? data.maintenance_schedule.maintenance_name : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Maintenance Cost Estimation</th>
                <td>`+(data.maintenance_schedule ? this.formatNumber(data.maintenance_schedule.cost_estimation) : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Work Order Part Usage</th>
                <td>`+this.formatNumber(data.inventory_cost)+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Print Time</th>
                <td>`+moment().format('lll')+`</td>
              </tr>
            </table>
          </td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3" style="text-center">
            <h3>Parts</h3>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:center;">Part Name</th>
                <th style="text-align:center;">Part Code</th>
                <th style="text-align:center;">UOM</th>
                <th style="text-align:center;">Brand</th>
                <th style="text-align:center;">SKU Number</th>
                <th style="text-align:center;">Qty</th>
                <th style="text-align:center;">Price / Qty</th>
                <th style="text-align:center;">Sub Total</th>
              </tr>`
            if(data.work_order_parts){
              data.work_order_parts.map(item => {
                text_html +=`<tr>
                  <td>`+(item.inventory && item.inventory.part ? item.inventory.part.part_name : '')+`</td>
                  <td>`+(item.inventory && item.inventory.part ? item.inventory.part.part_code : '')+`</td>
                  <td>`+(item.inventory && item.inventory.part ? item.inventory.part.uom : '')+`</td>
                  <td>`+(item.inventory && item.inventory.part ? item.inventory.part.brand : '')+`</td>
                  <td>`+(item.inventory && item.inventory.part ? item.inventory.part.sku_number : '')+`</td>
                  <td style="text-align:right;">`+this.formatNumber(item.qty)+`</td>
                  <td style="text-align:right;">`+this.formatNumber(item.price_qty)+`</td>
                  <td style="text-align:right;">`+this.formatNumber(item.qty * item.price_qty)+`</td>
                </tr>`
              })
          }
            text_html +=`</table>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:center;">Description</th>
                <th style="text-align:center;">Remarks</th>
              </tr>
              <tr>
                <td style="text-align:justify;white-space:pre;">`+data.description+`</td>
                <td style="text-align:justify;white-space:pre;">`+data.remarks+`</td>
              </tr>
              <tr>
                <th colspan="2" style="text-align:center;">Note</th>
              </tr>
              <tr>
                <td colspan="2" style="text-align:justify;"><br/><br/><br/><br/><br/><br/></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:center;width:35%">Work Order Completed by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Completion Checked by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>`;
      $('#section-to-print').append(text_html);
      window.print()
      $('#section-to-print').empty()
    },
    handlePrintTrip(data){
      $('#section-to-print').empty()
      var text_html = `<table style="width:100%">
        <tr>
          <td style="text-align:left"><h3>`+data.account.account_name+`</h3></td>
          <td></td>
          <td style="text-align:right"><h3>Trip Note</h3></td>
        </tr>
        <tr>
          <td colspan="2">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:left">Trip Note #</th>
                <td>`+data.trip_code+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Trip Date</th>
                <td>`+moment.utc(data.trip_date).format('ll')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Trip Route</th>
                <td>`+data.route.route_name+`</td>
              </tr>`
              if(data.sub_route){
              text_html+=`<tr>
                <th style="text-align:left">Sub Route</th>
                <td>`+data.sub_route.sub_route_name+`</td>
              </tr>`
            }
              text_html+=`<tr>
                <th style="text-align:left">Vehicle No</th>
                <td>`+(data.vehicle ? data.vehicle.vehicle_no : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle Code</th>
                <td>`+(data.vehicle ? data.vehicle.vehicle_code : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Driver</th>
                <td>`+(data.driver ? data.driver.name : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Planned Start</th>
                <td>`+moment.utc(data.estimation_start).format('lll')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Planned Finish</th>
                <td>`+moment.utc(data.estimation_finish).format('lll')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Print Time</th>
                <td>`+moment().format('lll')+`</td>
              </tr>
            </table>
          </td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th colspan="2" style="text-align:center;">Note</th>
              </tr>
              <tr>
                <td colspan="2" style="text-align:justify;"><br/><br/><br/><br/><br/><br/></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:center;width:35%">Trip Completed by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Trip Completion Checked by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>`;
      $('#section-to-print').append(text_html);
      window.print()
      $('#section-to-print').empty()
    },
    handlePrintSpda(data){
      var logo= require('../assets/logo.png')
      var text_html = `<head><style>@media print{@page {size: A4 landscape;}} table, th, td {
  border-collapse: collapse;
  font-size: 8px !important;
}</style></head><table style="width:100%;">
        <tr style="border-top:1px solid black;">
          <td colspan="4"><img src="`+((data.account && data.account.pic_url) ? data.account.pic_url : logo)+`" alt="Logo" class="brand-image" style="max-height:35px;"></td>
        </tr>
        <tr>
          <td colspan="4" style="padding:5px 0px !important;">Kantor Cabang</td>
        </tr>
        <tr style="border-bottom:1px solid black;">
          <td colspan="4" style="padding:5px 0px !important;">Alamat</td>
        </tr>
        <tr>
          <td colspan="4" style="padding:5px 0px !important;text-align:center;"><b>SURAT PERINTAH DINAS ANGKUTAN (SPDA) AP / 1</b></td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;">1. NO POLISI/CODE BUS</td>
          <td style="padding:5px 0px !important;">: `+(data.vehicle ? data.vehicle.vehicle_no : '')+`</td>
          <td style="padding:5px 0px !important;">5. BERANGKAT TANGGAL</td>
          <td style="padding:5px 0px !important;">: `+(data.start ? moment(data.start).format('YYYY-MM-DD') : '')+`</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;">2. NAMA PENGEMUDI</td>
          <td style="padding:5px 0px !important;">: `+(data.driver ? data.driver.name : '')+`</td>
          <td style="padding:5px 0px !important;">6. KEMBALI TANGGAL</td>
          <td style="padding:5px 0px !important;">: `+(data.finish ? moment(data.finish).format('YYYY-MM-DD') : '')+`</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;">3. TRAYEK</td>
          <td style="padding:5px 0px !important;">: `+(data.route ? data.route.route_name : '')+`</td>
          <td style="padding:5px 0px !important;">7. KONSUMSI BBM</td>
          <td style="padding:5px 0px !important;">: `+(data.bbm ? data.bbm + ' Liter' : '')+`</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;">4. JARAK TEMPUH</td>
          <td style="padding:5px 0px !important;">: `+(data.distance ? data.distance + ' KM' : '')+`</td>
          <td style="padding:5px 0px !important;">8. KETERANGAN</td>
          <td style="padding:5px 0px !important;">:</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="3"></td>
          <td style="padding:5px 0px !important;text-align:center;font-weight:bold;">GENERAL MANAGER</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4"></td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4"></td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="3"></td>
          <td style="padding:5px 0px !important;text-align:center;font-weight:bold;">_____________</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4">Rit ke: First</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;text-align: center;font-weight:bold;" colspan="4">LAPORAN MUATAN BUS (LMB) AP/2</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4">
            <table style="font-weight:bold;text-align:center;width: 100%;">
              <tr>
                <td rowspan="2" style="border:1px solid black;">KOTA TUJUAN</td>
                <td colspan="3" style="border:1px solid black;">JUMLAH PENUMPANG</td>
                <td rowspan="2" style="border:1px solid black;">KETERANGAN PENUMPANG</td>
                <td colspan="2" style="border:1px solid black;">JUMLAH</td>
                <td rowspan="2" style="border:1px solid black;">NO TICKET</td>
              </tr>
              <tr>
                <td style="border:1px solid black;">NAIK</td>
                <td style="border:1px solid black;">TURUN</td>
                <td style="border:1px solid black;">SISA</td>
                <td style="border:1px solid black;">PENUMPANG</td>
                <td style="border:1px solid black;">PENDAPATAN</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="3"></td>
          <td style="padding:5px 0px !important;text-align:center;font-weight:bold;">AWAK KENDARAAN/ CREW,</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4"></td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4"></td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="3"></td>
          <td style="padding:5px 0px !important;text-align:center;font-weight:bold;">_____________</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4">Rit ke: Second</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;text-align: center;font-weight:bold;" colspan="4">LAPORAN MUATAN BUS (LMB) AP/2</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4">
            <table style="font-weight:bold;text-align:center;width: 100%;">
              <tr>
                <td rowspan="2" style="border:1px solid black;">KOTA TUJUAN</td>
                <td colspan="3" style="border:1px solid black;">JUMLAH PENUMPANG</td>
                <td rowspan="2" style="border:1px solid black;">KETERANGAN PENUMPANG</td>
                <td colspan="2" style="border:1px solid black;">JUMLAH</td>
                <td rowspan="2" style="border:1px solid black;">NO TICKET</td>
              </tr>
              <tr>
                <td style="border:1px solid black;">NAIK</td>
                <td style="border:1px solid black;">TURUN</td>
                <td style="border:1px solid black;">SISA</td>
                <td style="border:1px solid black;">PENUMPANG</td>
                <td style="border:1px solid black;">PENDAPATAN</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="3"></td>
          <td style="padding:5px 0px !important;text-align:center;font-weight:bold;">AWAK KENDARAAN/ CREW,</td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4"></td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="4"></td>
        </tr>
        <tr>
          <td style="padding:5px 0px !important;" colspan="3"></td>
          <td style="padding:5px 0px !important;text-align:center;font-weight:bold;">_____________</td>
        </tr>
      </table>`;
      var win = window.open('about:blank', "_new");
      win.document.open();
      win.document.write(text_html);
      win.document.close();
      win.focus()
      setTimeout(() => win.print(), 1000)
    },
    handlePrintCaseIncident(data){
      $('#section-to-print').empty()
      var text_html = `<table style="width:100%;">
        <tr>
          <td style="text-align:left"><h3>`+data.account.account_name+`</h3></td>
          <td></td>
          <td style="text-align:right"><h3>Case Incident</h3></td>
        </tr>
        <tr>
          <td colspan="2">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:left">Incident Case #</th>
                <td>`+data.incident_code+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Created At</th>
                <td>`+moment(data.created_at).format('LLL')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle No</th>
                <td>`+(data.vehicle ? data.vehicle.vehicle_no : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle Code</th>
                <td>`+(data.vehicle ? data.vehicle.vehicle_code : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Driver</th>
                <td>`+(data.driver ? data.driver.name : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Type</th>
                <td>`+data.type+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Print Time</th>
                <td>`+moment().format('lll')+`</td>
              </tr>
            </table>
          </td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:center;">Description</th>
                <th style="text-align:center;">Remarks</th>
              </tr>
              <tr>
                <td style="text-align:justify;white-space:pre;">`+data.description+`</td>
                <td style="text-align:justify;white-space:pre;">`+data.remarks+`</td>
              </tr>
              <tr>
                <th colspan="2" style="text-align:center;">Note</th>
              </tr>
              <tr>
                <td colspan="2" style="text-align:justify;"><br/><br/><br/><br/><br/><br/></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:center;width:35%">Case Reported by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Case Resolved By</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>`;
      $('#section-to-print').append(text_html);
      window.print()
      $('#section-to-print').empty()
    },
    handlePrintDelivery(data){
      $('#section-to-print').empty()
      var text_html = `<table style="width:100%">
        <tr>
          <td style="text-align:left"><h3>`+data.account.account_name+`</h3></td>
          <td></td>
          <td style="text-align:right"><h3>`+data.delivery_title+`</h3></td>
        </tr>
        <tr>
          <td colspan="2">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:left">Delivery Note #</th>
                <td>`+data.delivery_code+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Delivery Date</th>
                <td>`+moment.utc(data.trip_date).format('ll')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle No</th>
                <td>`+(data.vehicle ? data.vehicle.vehicle_no : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle Code</th>
                <td>`+(data.vehicle ? data.vehicle.vehicle_code : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Driver</th>
                <td>`+(data.driver ? data.driver.name : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Note</th>
                <td>`+data.delivery_note+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Print Time</th>
                <td>`+moment().format('lll')+`</td>
              </tr>
            </table>
          </td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th>No</th>
                <th>Type</th>
                <th>Invoice Number</th>
                <th>Customer</th>
                <th>Address</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>`
        for(let i = 0;i<data.delivery_details.length;i++){
          text_html += `<tr>
            <td>`+data.delivery_details[i].sequence+`</td>
            <td>`+(data.delivery_details[i].type == 1 ? 'Pickup' : 'Delivery')+`</td>
            <td>`+(data.delivery_details[i].invoice_code ? data.delivery_details[i].invoice_code : '')+`</td>
            <td>`+(data.delivery_details[i].customer ? data.delivery_details[i].customer : '')+`</td>
            <td>`+data.delivery_details[i].address+`</td>
            <td>`+(data.delivery_details[i].description ? data.delivery_details[i].description : '')+`</td>
          </tr>`
    }
            text_html += `</tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:center;width:35%">Delivery Completed by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Delivery Completion Checked by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>`;
      $('#section-to-print').append(text_html);
      window.print()
      $('#section-to-print').empty()
    },
    handlePrintCost(data){
      $('#section-to-print').empty()
      var text_html = `<table style="width:100%">
        <tr>
          <td style="text-align:left"><h3>`+data.account.account_name+`</h3></td>
          <td></td>
          <td style="text-align:right"><h3>Cost Note</h3></td>
        </tr>
        <tr>
          <td colspan="2">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:left">Cost Note #</th>
                <td>`+data.code+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Delivery Date</th>
                <td>`+moment.utc(data.cost_date).format('ll')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle No</th>
                <td>`+(data.vehicle ? data.vehicle.vehicle_no : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Vehicle Code</th>
                <td>`+(data.vehicle ? data.vehicle.vehicle_code : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Driver</th>
                <td>`+(data.driver ? data.driver.name : '')+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Note</th>
                <td>`+data.note+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Total Amount</th>
                <td>`+this.formatNumber(data.total_cost)+`</td>
              </tr>
              <tr>
                <th style="text-align:left">Print Time</th>
                <td>`+moment().format('lll')+`</td>
              </tr>
            </table>
          </td>
          <td></td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>`
        for(let i = 0;i<data.cost_details.length;i++){
          text_html += `<tr>
            <td>`+(i+1)+`</td>
            <td>`+(data.cost_details[i].cost_name)+`</td>
            <td style="text-align:right">`+this.formatNumber(data.cost_details[i].cost)+`</td>
            <td>`+(data.cost_details[i].note)+`</td>
          </tr>`
        }
            text_html += `</tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <table class="table table-sm table-bordered">
              <tr>
                <th style="text-align:center;width:35%">Cost Approved by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Cost Paid by</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Date</th>
                <td style="text-align:justify;"></td>
              </tr>
              <tr>
                <th style="text-align:center;width:35%">Signature</th>
                <td style="text-align:justify;"><br/><br/><br/></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>`;
      $('#section-to-print').append(text_html);
      window.print()
      $('#section-to-print').empty()
    },
  },
  props:['title', 'entries', 'columns', 'currEntries', 'onRowClickEvent'],
}
</script>
<style>
.hoverable {
  cursor: pointer;
}
.selected{
  background-color: #3bb143 !important
}
@media print {
  body * {
    visibility: hidden;
  }
  body{
    -webkit-transform:unset;
    transform:unset;
    min-width: unset !important;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    margin:auto;
    height: auto;
    font-family: Arial;
    line-height: 1.5;
  }
  #section-to-print table tr th {
    padding: 10px;
  }
  #section-to-print table tr td {
    padding: 10px 10px;
  }
}
</style>
