<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20pt" height="20pt" viewBox="0 0 20 20" version="1.1">
    <g id="surface1">
      <path :style="' stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 19.667969 19 L 17.332031 19 C 17.515625 19 17.667969 18.851562 17.667969 18.667969 L 17.667969 17.332031 L 16.332031 17.332031 L 16.332031 18.667969 C 16.332031 18.851562 16.484375 19 16.667969 19 L 9.667969 19 C 9.851562 19 10 18.851562 10 18.667969 L 10 17.332031 L 8.667969 17.332031 L 8.667969 18.667969 C 8.667969 18.851562 8.816406 19 9 19 L 3.667969 19 L 3.667969 13 C 3.667969 12.632812 3.367188 12.332031 3 12.332031 L 3 5.972656 C 4.210938 5.796875 5.082031 4.71875 4.992188 3.496094 C 4.90625 2.277344 3.890625 1.332031 2.667969 1.332031 C 1.441406 1.332031 0.425781 2.277344 0.339844 3.496094 C 0.25 4.71875 1.121094 5.796875 2.332031 5.972656 L 2.332031 12.332031 C 1.964844 12.332031 1.667969 12.632812 1.667969 13 L 1.667969 19 L 0.332031 19 C 0.148438 19 0 19.148438 0 19.332031 C 0 19.515625 0.148438 19.667969 0.332031 19.667969 L 19.667969 19.667969 C 19.851562 19.667969 20 19.515625 20 19.332031 C 20 19.148438 19.851562 19 19.667969 19 Z M 19.667969 19 "/>
      <path :style="' stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 18 12.667969 C 18 13.035156 17.703125 13.332031 17.332031 13.332031 C 16.964844 13.332031 16.667969 13.035156 16.667969 12.667969 C 16.667969 12.296875 16.964844 12 17.332031 12 C 17.703125 12 18 12.296875 18 12.667969 Z M 18 12.667969 "/>
      <path :style="' stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 9.667969 12.667969 C 9.667969 13.035156 9.367188 13.332031 9 13.332031 C 8.632812 13.332031 8.332031 13.035156 8.332031 12.667969 C 8.332031 12.296875 8.632812 12 9 12 C 9.367188 12 9.667969 12.296875 9.667969 12.667969 Z M 9.667969 12.667969 "/>
      <path :style="' stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 7.175781 15.332031 L 7.753906 16.667969 L 18.578125 16.667969 L 19.160156 15.332031 Z M 7.175781 15.332031 "/>
      <path :style="' stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 14.332031 13.667969 L 12 13.667969 C 11.816406 13.667969 11.667969 13.816406 11.667969 14 L 11.667969 14.667969 L 14.667969 14.667969 L 14.667969 14 C 14.667969 13.816406 14.515625 13.667969 14.332031 13.667969 Z M 14.332031 13.667969 "/>
      <path :style="' stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 8.667969 10 L 17.667969 10 C 17.851562 10 18 9.851562 18 9.667969 L 18 5 C 18 4.816406 17.851562 4.667969 17.667969 4.667969 L 8.667969 4.667969 C 8.484375 4.667969 8.332031 4.816406 8.332031 5 L 8.332031 9.667969 C 8.332031 9.851562 8.484375 10 8.667969 10 Z M 8.667969 10 "/>
      <path :style="' stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 8.667969 2.667969 L 17.667969 2.667969 C 17.851562 2.667969 18 2.515625 18 2.332031 L 18 2 C 18 1.816406 17.851562 1.667969 17.667969 1.667969 L 8.667969 1.667969 C 8.484375 1.667969 8.332031 1.816406 8.332031 2 L 8.332031 2.332031 C 8.332031 2.515625 8.484375 2.667969 8.667969 2.667969 Z M 8.667969 2.667969 "/>
      <path :style="' stroke:none;fill-rule:nonzero;fill:'+fill+';fill-opacity:1;'" d="M 7 14.667969 L 11 14.667969 L 11 14 C 11 13.449219 11.449219 13 12 13 L 14.332031 13 C 14.886719 13 15.332031 13.449219 15.332031 14 L 15.332031 14.667969 L 19.332031 14.667969 L 19.332031 1.332031 C 19.371094 0.824219 18.992188 0.378906 18.484375 0.332031 L 7.847656 0.332031 C 7.339844 0.378906 6.960938 0.824219 7 1.332031 Z M 17.332031 14 C 16.597656 14 16 13.402344 16 12.667969 C 16 11.929688 16.597656 11.332031 17.332031 11.332031 C 18.070312 11.332031 18.667969 11.929688 18.667969 12.667969 C 18.664062 13.402344 18.070312 14 17.332031 14 Z M 7.667969 2 C 7.667969 1.449219 8.113281 1 8.667969 1 L 17.667969 1 C 18.21875 1 18.667969 1.449219 18.667969 2 L 18.667969 2.332031 C 18.667969 2.886719 18.21875 3.332031 17.667969 3.332031 L 8.667969 3.332031 C 8.113281 3.332031 7.667969 2.886719 7.667969 2.332031 Z M 7.667969 5 C 7.667969 4.449219 8.113281 4 8.667969 4 L 17.667969 4 C 18.21875 4 18.667969 4.449219 18.667969 5 L 18.667969 9.667969 C 18.667969 10.21875 18.21875 10.667969 17.667969 10.667969 L 8.667969 10.667969 C 8.113281 10.667969 7.667969 10.21875 7.667969 9.667969 Z M 9 11.332031 C 9.738281 11.332031 10.332031 11.929688 10.332031 12.667969 C 10.332031 13.402344 9.734375 14 9 14 C 8.261719 14 7.667969 13.402344 7.667969 12.667969 C 7.667969 11.929688 8.261719 11.332031 9 11.332031 Z M 9 11.332031 "/>
    </g>
  </svg>
</template>
<script>
  export default {
    props: ['fill']
  }
</script>
